:root {
  --body-bg: #f5f8ff;
  --box-bg: #fff;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #b6b5b5;
  --border-color: #006cbe;;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.App {
  text-align: center;
  margin: 0;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.formularioHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  }
  .formularioContainer {
   border-radius: 25px;
   padding: 20px;
   margin: auto;
   margin-top: 20px;
   max-width: 700px;
   background-color: white;
   border: 2px solid #a9a9a9;
  }

.logOut_button{
  background-color: #006cbe; /* Green */
position: relative;  
border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  }

  .header_nav{
    background-color: #006cbe;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_items {
    display: flex;
    align-items: right;
    justify-content: right;
    width: 700px;
    margin-right: 10px;
    color: white;
    text-decoration: none;
  }
  
  .material-icons{
    cursor: pointer;
  }

.Login_page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  background-color: #79a0ab;
  width: 300px;
  border-radius: 5px;
  padding-bottom: 40px;
  margin: auto;
}
.formulario_main{

}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.menu-toggles{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-toggle:hover {
  background-color: #0c365e; /* Color de fondo al pasar el mouse */
  border-right: 4px solid #ff5722; /* Resaltado al pasar el mouse */

}