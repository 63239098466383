.login_page_container{
  display: flex;
  background-image: url("../img/brazo-robotico-fondo-azul-renderizacion-3d-dibujo-digital-computadora.jpg");
    height: 100vh;
    background-size: cover; /* Add this line to ensure the image covers the container */
  }
 
  .login-page {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    backdrop-filter: blur(10px); /* Adjust the blur value to control the intensity of the frosted glass effect */
    background: rgba(51, 153, 206, 0.22);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
border-right: 2px solid rgba(51, 153, 206, 0.3);
  }
    
    .login-page h1 {
      font-size: 2rem;
      margin-bottom: 20px;
    }
    
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
    
    .form-group label {
      font-size: 1.2rem;
      margin-bottom: 5px;
      text-align: left;
      color: #fff;
    }
    
    .form-group input {
      padding: 10px;
      width: 220px;
      font-size: .8rem;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
    
    .button_submit {
      margin: 30px 0;
      padding: 10px;
      font-size: 1rem;
      border-radius: 5px;
      background-color: #757a7f00;
      border: 2px solid #ffff;
      color: #fff;
      cursor: pointer;
    }
    
    .button_submit:hover {
      background-color: #ededed;
      border: 2px solid #ffff;
      color: #006cbe;
    }