/* NavigationMenu.css */

.navigation-menu {
    left: 0;
    top: 0;
    width: 60px; /* Ancho del menú lateral */
    background-color: #034c84; /* Color de fondo */
    color: #fff; /* Color del texto */
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    transition: width 0.3s ease;
    text-align: left;
    cursor: pointer;
    z-index: 99;
    
  }
  
  .navigation-menu.open {
    width: 200px; /* Ancho expandido cuando el menú está abierto */
  }
  .hide{
    display: none;
  }

  .show{
    display: inline;
    margin: 0px 8px;
  }
  .menu-toggle {
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
  }
  
  .menu-items {
    display: flex;
    flex-direction: column;
  }
  
  .menu-item {
    display: flex;
    padding: 10px;
    text-decoration: none;
    color: #fff;
  }
  
  .menu-item:hover {
    background-color: #0c365e; /* Color de fondo al pasar el mouse */
    border-right: 4px solid #ff5722; /* Resaltado al pasar el mouse */
  }
  
 
