/* Estilos para hacer el formulario responsive */

.container {
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
}

form {
  border-radius: 5px;

}
textarea {
  resize: none;
}

h4 {
  font-size: 16px;
  margin-bottom: 10px;
}
h2 {
  text-align: center;
  color: #006cbe;
}

.input-field,
.select {
  width: 525px;
  height: 60px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.confirmacion_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;

}
.confirmacion_container img{
  width: 300px;
  padding-top: 50px;
  animation: subirYBajar 3s infinite alternate ease-in-out;
}
.texto_confirmacion{
  color: #008000;
  font-size: 14px;
  font-weight: 500;
} 
.button_form {
  background-color: #006cbe; /* Green */
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  margin: 30px 0px;
}


.button_form:hover {
  background-color: #ffffff;
  color: #006cbe;
  border: 2px solid #006cbe;
}
@keyframes subirYBajar {
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-50px); /* Ajusta la distancia que sube la imagen */
}
}
/* Estilos específicos para dispositivos móviles */
@media (max-width: 600px) {
  form {
    padding: 10px;
  }
  
  .input-field,
  .select {
    padding: 8px;
  }

  h4 {
    font-size: 14px;
  }
}

.confirmacion_container img{
width: 220px;
} 

.header_request{
display: flex;
justify-content:space-around;
align-items: center;
gap: .25rem;
}
.item_container{
display: flex;
justify-content: center;
align-items: center;
gap: .25rem;
}
.title_container{
background-color: #006cbe;
color: white;
}

h5{
width: 150px;
color: #4a4a4a;
}
.footer_request{
display: flex;
align-items: center;
justify-content: center;
gap: .25rem;
margin-top: 1rem;
margin-bottom: 1rem;
}
.mensaje_error{
width: 220px;
 border-radius: 5px;
 border-left: 10px solid rgb(72, 10, 10);
 height: 26px;
 font-size: 14px;
 padding: 5px;
 background-color: #e1e6e8;
}
.form-control{
font-size: 12px;
}
.title_login{
color: #ffffff;
}
.ReactModal__Overlay{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: rgba(156, 156, 156, 0.75);

}
.modal{
padding: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: rgba(255, 255, 255, 0.956);
border-radius: 10px;
border: 2px solid #8484844a;

}

.item_not_found{
color: #a91111;
}
.ReactModal__Overlay .ReactModal__Overlay--after-open{
background-color: rgba(156, 156, 156, 0.75);
}


.delete_button {
background-color: #d5d5d5;
border: 1px solid #ccc;
border-radius: 4px;
color: #ffffff;
cursor: pointer;
font-size: 14px;
padding: 8px 15px;
height: 60px;

}
.delete_button:hover {
background-color: #d9a9a94c;
}


.material-icons-delete{
color: #000000;

}
