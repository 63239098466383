@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css");

.main{
	display: flex;
}

.table {
	flex: 1 1;
	transition: margin 0.3s ease; /* Add a transition effect to the margin property */	
 height: 100vh;
 padding:5px 20px;
}

.container-table{
width: 100vw;
}
.welcome{
position: absolute;
right: 0;
padding-right: 20px;
}
.row {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: .5rem;
	padding: 5px;
}
.light{
 background-color: #f7f7f7;
}
.dark{
	background-color: #d3d7d9;
}
.row-item{
	margin: auto;

}
.row-items{
border-left: 1px solid #8d8d8d;
border-right: 1px solid #8d8d8d;

}
.title {
	display: flex;
	justify-content: center;
	background-color: #1f5279;
	color: white;
}

.menu-top {
	display: flex;
	flex-direction: column;
	border: 1px solid #bdbdbd;
	margin: 5px;
	padding: .25rem;
	border-radius: 5px;
	font-size: 14px;
}
.header {
	padding: 10px;
	background-color: #d3d7d9;
	padding: 10px;
	border: 1px solid #8d8d8d;
	border-top: 1px solid #8d8d8d;
	cursor: pointer;
}

.body .row {
	padding: 10px;
	cursor: pointer;
}

.body .row div {
	display: flex;
	justify-content: center;
	margin: auto;
}

.footer .row {
	padding: 5px;
	background-color: #777777;
}

.footer .row div {
	display: flex;
	justify-content: center;
	color: white;
}

.options {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	font-size: 14px;
	padding: 16px;
}

.search-input input{
padding: 5px;
}
.header-second{
display: flex;
}
.options-header {
	display: flex;
	padding: 0 20px;
	color: #05385f;
	justify-content: space-between;
	align-items:center ;
}
.fa-caret-right, .fa-caret-left{
cursor: pointer;
color: #006cbe;
font-size: 20px;
}
.fa-caret-right, .fa-caret-left :hover{
	color: #05385f;
	font-size: 20px;
	}

.page-active {
	color: rgb(110, 110, 110);
	font-size: 20px;
	padding: 10px;
}

.update_button {
	width: 34px;
	background-color: #006cbe;
	color: white;
	border: none;
	padding: 5px;
	border-radius: 5px;
	cursor: pointer;
	margin: auto;
}

.icon_material-icons {
	cursor: pointer;
	text-align: center;

}

.options-container {
	width: 100%;
	background-color: #f7f7f7;
	border: 1px solid #8d8d8d;
}
  .create-new-button{
	background-color: #006cbe;
	border: none;
	color: white;
	padding: 16px 32px;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	cursor: pointer;
	margin: 30px 0px;
  }
  .create-new-button:hover{
	background-color: #ffff;
	border: 2px solid #006cbe;
	color: #006cbe;

  }
  .container-null{
	padding:100px;
	height: 500px;
  }